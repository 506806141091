* {
  box-sizing: border-box;
}

body {
  font-family: $primary-font;
  background-color: $light-blue-grey;
  font-size: 0.875rem;
  color: $grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  padding-top: 10vw;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
