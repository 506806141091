.login-form__input {
  margin-top: 20px;
}

.login-form__input--checkbox {
  margin: 30px 0;
}

.login-form__input--submit {
  display: flex;
  align-items: center;

  .loader {
    margin-left: 20px;
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.login-form__error {
  margin-top: 30px;
  max-width: 600px;
  padding: 2rem;
  border: $line;
}

.button {
  background-color: $ocean;
  height: 38px;
  min-width: 95px;
  padding: 0 1rem;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  align-items: center;

  &:focus,
  &:hover {
    background-color: $hover-color;
  }
}

.progress {
  height: 20px;
  overflow: hidden;
  background-color: #eee;
  border-radius: 2px;
  position: relative;
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  line-height: 20px;
  color: $dark-grey;
  text-align: center;
  background-color: $ocean-2;

  span {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
  }
}

.card-small,
.card-big {
  background-color: #fff;
  box-shadow: $shadow;
  margin-bottom: 2em;
}

.card-small__header {
  border-bottom: $line;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 22px;
  justify-content: flex-end;
}

.card-small__content {
  padding: 16px;

  .progress {
    margin-bottom: 10px;
  }
}

.card-small__title {
  margin: 0 auto 0 0;
}

.card-small__details-link,
.card-small__edit-link {
  font-size: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  width: 20px;
  height: 20px;
}

.card-small__edit-link {
  background-image: url("../../images/edit-icon.svg");
}
.card-small__details-link {
  background-image: url("../../images/details-icon.svg");
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.card-big__header {
  display: flex;
  justify-content: space-between;
  height: 65px;
  align-items: center;
  padding: 0 30px;
  border-bottom: $line;
  cursor: pointer;

  h3 {
    margin: 0;
  }
}

.card-big__expand-collapse {
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 0;
  background-image: url("../../images/arrow-down-icon.svg");
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  outline: none;

  .collapsed & {
    transform: rotate(180deg);
  }
}

.card-big__content {
  padding: 30px;

  .collapsed & {
    display: none;
  }
}
.search {
  margin: 0 20px 0 0;
  display: inline-flex;
  position: relative;
  width: 100%;
  max-width: 370px;

  &:after {
    background-image: url("../../images/search-icon.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    z-index: 2;
  }
  input {
    padding-right: 36px;
    max-width: 100%;
  }
}

.admin-header,
.client-header {
  display: flex;
  align-items: center;
  margin-bottom: 2em;

  button {
    margin-left: auto;
  }
}

.modal {
  transition: 0.3s all;
  opacity: 1;
  visibility: visible;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.modal__dialog {
  position: fixed;
  width: calc(100% - 80px);
  max-width: 1100px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s all;

  .hidden & {
    transform: translate(-50%, -60%);
  }
}

.modal__header {
  display: flex;
  justify-content: space-between;
  height: 65px;
  align-items: center;
  padding: 0 30px;
  border-bottom: $line;

  h3 {
    margin: 0;
  }
}

.modal__close {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.modal__body {
  padding: 30px;
}

.progress-bars {
  display: flex;
  max-width: 600px;
  margin-bottom: 20px;

  .progress {
    width: 50%;
    margin-right: 20px;
  }
}

.tasks {
  width: 100%;

  td,
  th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: $line;
    text-align-last: left;
  }

  thead th {
    vertical-align: bottom;
    border-top: none;
  }
}
