h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin-top: 0;
  color: $medium-grey;
}

h1 {
  font-size: 1.625rem;
}

h3 {
  font-size: 1rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  line-height: 1.5;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: $ocean;

  &:hover,
  &:focus {
    color: $hover-color;
  }
}

li {
  margin-left: 20px;

  line-height: 1.5;
}

ol li {
  list-style: inherit;
}
