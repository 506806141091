input[type="text"],
input[type="password"] {
  background: #fff;
  border: 1px solid #ced4e2;
  box-shadow: 0 4px 10px 0 rgba(189, 199, 222, 0.23);
  border-radius: 2px;
  padding: 0.8rem 1rem;
  max-width: 270px;
  width: 100%;
  color: $dark-grey;

  &::placeholder {
    color: $grey;
  }
}

// Checkbox
.check-container {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  height: 28px;
  display: flex;
  align-items: center;

  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      border-color: $ocean;
      border-width: 2px;

      &:after {
        display: block;
      }
    }
  }

  &:hover input ~ .checkmark {
    border-width: 2px;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: #fff;
  border: solid 1px $dark-grey;
  border-radius: 2px;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 10px;
    top: 6px;
    width: 4px;
    height: 8px;
    border: solid $ocean;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

input[type="submit"] {
  @extend .button;
}
