/* Uncomment and set these variables to customize the grid. */
[class^="col-"] {
  width: 100%;
}

.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2em;
  padding-left: 2em;
}

.row {
  display: flex;
  margin-right: -1em;
  margin-left: -1em;

  flex-wrap: wrap;

  @media (max-width: 767px) {
    margin: 0;
  }
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  padding-right: 1em;
  padding-left: 1em;

  flex: 0 0 auto;
}

.col-xs {
  max-width: 100%;

  flex-grow: 1;
  flex-basis: 0;
}

.col-xs-1 {
  max-width: 8.333%;

  flex-basis: 8.333%;
}

.col-xs-2 {
  max-width: 16.667%;

  flex-basis: 16.667%;
}

.col-xs-3 {
  max-width: 25%;

  flex-basis: 25%;
}

.col-xs-4 {
  max-width: 33.333%;

  flex-basis: 33.333%;
}

.col-xs-5 {
  max-width: 41.667%;

  flex-basis: 41.667%;
}

.col-xs-6 {
  max-width: 50%;

  flex-basis: 50%;
}

.col-xs-7 {
  max-width: 58.333%;

  flex-basis: 58.333%;
}

.col-xs-8 {
  max-width: 66.667%;

  flex-basis: 66.667%;
}

.col-xs-9 {
  max-width: 75%;

  flex-basis: 75%;
}

.col-xs-10 {
  max-width: 83.333%;

  flex-basis: 83.333%;
}

.col-xs-11 {
  max-width: 91.667%;

  flex-basis: 91.667%;
}

.col-xs-12 {
  max-width: 100%;

  flex-basis: 100%;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 736px;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    padding-right: 1em;
    padding-left: 1em;

    flex: 0 0 auto;
  }

  .col-sm {
    max-width: 100%;

    flex-grow: 1;
    flex-basis: 0;
  }

  .col-sm-1 {
    max-width: 8.333%;

    flex-basis: 8.333%;
  }

  .col-sm-2 {
    max-width: 16.667%;

    flex-basis: 16.667%;
  }

  .col-sm-3 {
    max-width: 25%;

    flex-basis: 25%;
  }

  .col-sm-4 {
    max-width: 33.333%;

    flex-basis: 33.333%;
  }

  .col-sm-5 {
    max-width: 41.667%;

    flex-basis: 41.667%;
  }

  .col-sm-6 {
    max-width: 50%;

    flex-basis: 50%;
  }

  .col-sm-7 {
    max-width: 58.333%;

    flex-basis: 58.333%;
  }

  .col-sm-8 {
    max-width: 66.667%;

    flex-basis: 66.667%;
  }

  .col-sm-9 {
    max-width: 75%;

    flex-basis: 75%;
  }

  .col-sm-10 {
    max-width: 83.333%;

    flex-basis: 83.333%;
  }

  .col-sm-11 {
    max-width: 91.667%;

    flex-basis: 91.667%;
  }

  .col-sm-12 {
    max-width: 100%;

    flex-basis: 100%;
  }
}

@media only screen and (min-width: 62em) {
  .container {
    width: 976px;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    padding-right: 1em;
    padding-left: 1em;

    flex: 0 0 auto;
  }

  .col-md {
    max-width: 100%;

    flex-grow: 1;
    flex-basis: 0;
  }

  .col-md-1 {
    max-width: 8.333%;

    flex-basis: 8.333%;
  }

  .col-md-2 {
    max-width: 16.667%;

    flex-basis: 16.667%;
  }

  .col-md-3 {
    max-width: 25%;

    flex-basis: 25%;
  }

  .col-md-4 {
    max-width: 33.333%;

    flex-basis: 33.333%;
  }

  .col-md-5 {
    max-width: 41.667%;

    flex-basis: 41.667%;
  }

  .col-md-6 {
    max-width: 50%;

    flex-basis: 50%;
  }

  .col-md-7 {
    max-width: 58.333%;

    flex-basis: 58.333%;
  }

  .col-md-8 {
    max-width: 66.667%;

    flex-basis: 66.667%;
  }

  .col-md-9 {
    max-width: 75%;

    flex-basis: 75%;
  }

  .col-md-10 {
    max-width: 83.333%;

    flex-basis: 83.333%;
  }

  .col-md-11 {
    max-width: 91.667%;

    flex-basis: 91.667%;
  }

  .col-md-12 {
    max-width: 100%;

    flex-basis: 100%;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    width: 1136px;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    padding-right: 1em;
    padding-left: 1em;

    flex: 0 0 auto;
  }

  .col-lg {
    max-width: 100%;

    flex-grow: 1;
    flex-basis: 0;
  }

  .col-lg-1 {
    max-width: 8.333%;

    flex-basis: 8.333%;
  }

  .col-lg-2 {
    max-width: 16.667%;

    flex-basis: 16.667%;
  }

  .col-lg-3 {
    max-width: 25%;

    flex-basis: 25%;
  }

  .col-lg-4 {
    max-width: 33.333%;

    flex-basis: 33.333%;
  }

  .col-lg-5 {
    max-width: 41.667%;

    flex-basis: 41.667%;
  }

  .col-lg-6 {
    max-width: 50%;

    flex-basis: 50%;
  }

  .col-lg-7 {
    max-width: 58.333%;

    flex-basis: 58.333%;
  }

  .col-lg-8 {
    max-width: 66.667%;

    flex-basis: 66.667%;
  }

  .col-lg-9 {
    max-width: 75%;

    flex-basis: 75%;
  }

  .col-lg-10 {
    max-width: 83.333%;

    flex-basis: 83.333%;
  }

  .col-lg-11 {
    max-width: 91.667%;

    flex-basis: 91.667%;
  }

  .col-lg-12 {
    max-width: 100%;

    flex-basis: 100%;
  }
}
