/* ==========================================================================
    COLORS
    ========================================================================== */
$grey: #7d7d7d;
$medium-grey: #686868;
$dark-grey: #5b5b5b;
$ocean: #0047be;
$ocean-2: #7cbbb9;
$hover-color: #555;
$light-blue-grey: #f5f6fa;

/* ==========================================================================
    FONTS
    ========================================================================== */
$primary-font: "Lato", sans-serif;

/* ==========================================================================
    DIV
    ========================================================================== */
$shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
$line: 1px solid rgba(209, 216, 245, 0.6);
